import { Controller } from "stimulus"
import flatpickr from "flatpickr"
import "flatpickr/dist/themes/light.css"
import flatpickrLocales from "flatpickr/dist/l10n"

export default class extends Controller {
    // lifecycle
    connect() {
        const locale = document.querySelector("body").dataset.locale || "de"

        flatpickr(this.element, {
            altInput: true,
            altFormat: "d.m.Y",
            allowInput: true,
            // TODO Reduce bundle size (beware: locale 'en' is named 'default'): (await import(`flatpickr/dist/l10n/${locale}`).default?.[locale]
            locale: flatpickrLocales[locale]
        })
    }
}
