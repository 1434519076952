// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// We're moving this as far up as possible to ensure __webpack_nonce__ is available everywhere.
import "../src/local_csp_nonce";
import "../src/turbo_csp_nonce_fix";

import * as ActiveStorage from "@rails/activestorage";
import "@hotwired/turbo-rails";
import "channels";

import Trix from "trix";
import {
  addTrixBlockAttributes,
  addTrixTextAttributes,
  setDefaultTagForTrixBlockElements,
} from "../src/trix/custom_attributes";
addTrixBlockAttributes();
addTrixTextAttributes();
setDefaultTagForTrixBlockElements();

import "bootstrap";
import "../stylesheets/application";
import "@awesome.me/kit-29f35e10ac/icons/css/all";
import "controllers";
import "optional_controllers";
import "../src/nested_forms";
import "chartkick/chart.js";

import { polyfillRequestSubmit } from "../src/polyfills";
import { installTurboHooks } from "../src/turbo_hooks";
polyfillRequestSubmit();
installTurboHooks();

ActiveStorage.start();

const images = require.context("../images", true);
window.bootstrap = require("bootstrap");

require("jquery");
require("@rails/actiontext");
