import { Controller } from "stimulus"
import { addAlert } from "../src/dom_helper"
import { getLocaleFromUrl } from "../src/helper"
import { SSID_PUBLIC_LOGIN_ERROR, SSID_PUBLIC_LOGIN_KEY, SSID_PUBLIC_NOTE_UUID } from "../config/storage_identifiers"

// Static values

const ERROR_MESSAGE_INVALID_PASSWORD = {
  de: "Das Passwort ist leider nicht korrekt.",
  en: "Unfortunately, the password is not correct."
}

const ERROR_MESSAGE_LEGACY_URL = {
  de: "Bitte nutzen Sie für Hinweise, die <strong>vor dem 17.04.2024</strong> eingereicht wurden, die Loginadresse aus Ihren Zugangsdaten.",
  en: "Please use the login address from your access data for reports submitted <strong>before 17 April 2024</strong>."
}

const LENGTHS = {
  combinedPassword: 60,
  combinedShortPassword: [42, 46], // ID (base58) is 18-22, hash is 1 secretKey is 23
  secretKey: 23
}

const REGEXES = {
  uuid: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
  shortId: /^[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{18,22}$/,
  key: /^[a-z0-9]{5}-[a-z0-9]{5}-[a-z0-9]{5}-[a-z0-9]{5}$/i
}

// Helpers

const isValidCombinedValue = (combinedValue) => {
  if (!combinedValue) { return false }
  if (combinedValue.length === LENGTHS.combinedPassword) { return true }
  if (combinedValue.length >= LENGTHS.combinedShortPassword[0] && combinedValue.length <= LENGTHS.combinedShortPassword[1]) { return true }

  return false
}

const isValidSecretKey = (key) => { return key?.match(REGEXES.key) }
const isValidId = (id) => { return (id?.match(REGEXES.uuid) || id?.match(REGEXES.shortId)) }

const isValidFormat = (keyMaterial) => {
  return isValidId(keyMaterial?.uuid) && isValidSecretKey(keyMaterial?.key)
}

const parseCombinedLoginData = (combinedValue) => {
  if (!isValidCombinedValue(combinedValue)) { return }

  const parts = combinedValue.split("#", 2)
  const loginData = {
    uuid: parts[0],
    key: parts[1]?.toUpperCase()
  }

  if (isValidFormat(loginData)) { return loginData }
}

const parseSecretKeyWithInitialUuid = (initialUuid, secretKey) => {
  if (!initialUuid) { return }
  if (!secretKey) { return }
  if (secretKey.length != LENGTHS.secretKey) { return }

  const loginData = {
    uuid: initialUuid,
    key: secretKey.toUpperCase()
  }

  if (isValidFormat(loginData)) { return loginData }
}

// The actual component

export default class extends Controller {
  static targets = [
    "alertContainer",
    "initialUuidSection",
    "passwordInput",
    "submitButton"
  ]

  static values = { loginErrorMessage: String }

  // Lifecycle

  connect() {
    this.uuid = null
    this.key = null
    // This is the UUID the User tried to access before being redirected here
    this.initialUuid = sessionStorage.getItem(SSID_PUBLIC_NOTE_UUID)

    this.extractLoginDataFromUrlPath()
    window.setTimeout(() => { this.showErrorMessageBasedOnUrlParam() }, 10)

    // Since only the PublicNoteDecryptionController can check the validity of the secret key, we're getting redirected here when it is wrong. We're using this field in the sessionStorage to indicate that there was an error so we can show an error message.
    const loginErrorMessage = sessionStorage.getItem(SSID_PUBLIC_LOGIN_ERROR)
    if (loginErrorMessage) { this.showInvalidPasswordAlert() }
  }

  // Entrypoints

  login(event = null) {
    event?.preventDefault()
    event?.stopPropagation()

    this.checkFormatAndAssignLoginData()

    if (!this.uuid || !this.key) { return }

    sessionStorage.removeItem(SSID_PUBLIC_NOTE_UUID)
    sessionStorage.setItem(SSID_PUBLIC_LOGIN_KEY, this.key)
    Turbo.visit(`/${getLocaleFromUrl()}/notes/${this.uuid}`)
  }

  // Internal Actions

  checkFormatAndAssignLoginData() {
    const loginData = parseCombinedLoginData(this.passwordInputTarget.value) ||
      parseSecretKeyWithInitialUuid(this.initialUuid, this.passwordInputTarget.value)

    if (!loginData) {
      const locale = document.querySelector("body").dataset.locale || "de"
      let errorMessage = ERROR_MESSAGE_INVALID_PASSWORD[locale] || ERROR_MESSAGE_INVALID_PASSWORD["de"]

      if (isValidSecretKey(this.passwordInputTarget.value)) {
        errorMessage = ERROR_MESSAGE_LEGACY_URL[locale] || ERROR_MESSAGE_LEGACY_URL["de"]
      }

      this.showInvalidPasswordAlert(errorMessage)
      return
    }

    this.uuid = loginData.uuid
    this.key = loginData.key
  }

  extractLoginDataFromUrlPath() {
    const pathSegments = window.location.pathname.split("/").filter(n => n)
    const uuid = pathSegments[pathSegments.length - 1]
    const combinedLoginData = `${uuid}${window.location.hash}`
    const loginData = parseCombinedLoginData(combinedLoginData)
    if (!isValidFormat(loginData)) { return }

    this.passwordInputTarget.value = combinedLoginData

    this.login()
  }

  showErrorMessageBasedOnUrlParam() {
    const params = new URLSearchParams(window.location.search)
    const errorCode = params.get("error_code")

    if (errorCode === "404") {
      const locale = document.querySelector("body").dataset.locale || "de"
      const errorMessage = ERROR_MESSAGE_INVALID_PASSWORD[locale] || ERROR_MESSAGE_INVALID_PASSWORD["de"]
      this.showInvalidPasswordAlert(errorMessage)
    }
  }

  showInvalidPasswordAlert(optionalMessage) {
    const message = optionalMessage || this.loginErrorMessageValue
    addAlert("warning", message, this.alertContainerTarget)

    sessionStorage.removeItem(SSID_PUBLIC_LOGIN_ERROR)
  }
}
