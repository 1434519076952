import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      ghostClass: "ghost",
      handle: ".handle",
      onEnd: this.submitNewPosition.bind(this)
    })
  }

  async submitNewPosition(event) {
    const id = event.item.dataset.id
    const url = this.data.get("url").replace(":id", id)
    const csrfToken = document.querySelector("meta[name=csrf-token]")?.content
    const data = new FormData()

    data.append("position", event.newIndex + 1)

    const response = await fetch(url, {
      method: "PATCH",
      headers: { "X-CSRF-Token": csrfToken },
      body: data
    })

    if (!response.ok) {
      console.error("Failed to change the position (drag controller).")
      alert("Element position could not be changed.")
    }
  }
}
