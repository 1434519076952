import Trix from "trix"

export function addTrixBlockAttributes() {
  Trix.config.blockAttributes.heading2 = {
    tagName: "h2",
    terminal: true,
    breakOnReturn: true,
    group: false,
  },
  Trix.config.blockAttributes.heading3 = {
    tagName: "h3",
    terminal: true,
    breakOnReturn: true,
    group: false,
  },
  Trix.config.blockAttributes.mark = {
    tagName: "mark",
    terminal: true,
    breakOnReturn: true,
    group: false,
  },
  Trix.config.blockAttributes.alignLeft = {
    tagName: "trix-align-left",
    terminal: true,
    breakOnReturn: true,
    group: false,
  },
  Trix.config.blockAttributes.alignRight = {
    tagName: "trix-align-right",
    terminal: true,
    breakOnReturn: true,
    group: false,
  },
  Trix.config.blockAttributes.alignJustify = {
    tagName: "trix-align-justify",
    terminal: true,
    breakOnReturn: true,
    group: false,
  }
}

export function addTrixTextAttributes() {
  Trix.config.textAttributes.underline = {
    tagName: "u",
    inheritable: true,
    parser: function(element) {
      const style = window.getComputedStyle(element)
      return style.textDecoration === "underline"
    }
  }
}

export function setDefaultTagForTrixBlockElements() {
  Trix.config.blockAttributes.default.tagName = "p"
}
