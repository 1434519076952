import addFields from "./addFields"
import removeFields from "./removeFields"

// Wait for turbo to load, otherwise `document.querySelectorAll()` won't work
(globalWindow => {
  globalWindow.addEventListener('turbo:load', () => {
    new addFields()
    new removeFields()
  })
})(window)
