import { Controller } from "stimulus"

const SELECTORS = {
  h1Button: ".trix-button--icon-heading-1",
  italicButton: ".trix-button--icon-italic",
  strikeThroughButton: ".trix-button--icon-strike",
  groupTextTools: ".trix-button-group--text-tools",
  groupAlignmentTools: ".trix-button-group--alignment-tools",
}

const NEW_BLOCK_BUTTONS = {
  h2: {
    selector: `[data-trix-attribute="heading2"]`,
    template: `
      <button
        type="button"
        class="trix-button"
        data-trix-attribute="heading2"
        title="Heading 2"
        tabindex="-1"
      >H2</button>
    `
  },
  h3: {
    selector: `[data-trix-attribute="heading3"]`,
    template: `
      <button
        type="button"
        class="trix-button"
        data-trix-attribute="heading3"
        title="Heading 3"
        tabindex="-1"
      >H3</button>
    `
  },
  mark: {
    selector: `[data-trix-attribute="mark"]`,
    template: `
      <button
        type="button"
        class="trix-button"
        data-trix-attribute="mark"
        title="Emphasized text"
        tabindex="-1"
      ><i class="fa fa-highlighter"></i></button>
    `
  },
}

const NEW_TEXT_BUTTONS = {
  underline: {
    selector: `[data-trix-attribute="underline"]`,
    template: `
      <button
        type="button"
        class="trix-button trix-button--underline"
        data-trix-attribute="underline"
        title="Underline"
        tabindex="-1"
      ><u>U</u></button>
    `
  }
}

const NEW_ALIGNMENT_BUTTONS = {
  alignLeft: {
    selector: `[data-trix-attribute="alignLeft"]`,
    template: `
      <button
        type="button"
        class="trix-button has--font-awesome"
        data-trix-attribute="alignLeft"
        title="Left alignment"
        tabindex="-1"
      ><i class="fa fa-align-left"></i></button>
    `
  },
  alignRight: {
    selector: `[data-trix-attribute="alignRight"]`,
    template: `
      <button
        type="button"
        class="trix-button has--font-awesome"
        data-trix-attribute="alignRight"
        title="Right alignment"
        tabindex="-1"
      ><i class="fa fa-align-right"></i></button>
    `
  },
  alignJustify: {
    selector: `[data-trix-attribute="alignJustify"]`,
    template: `
      <button
        type="button"
        class="trix-button has--font-awesome"
        data-trix-attribute="alignJustify"
        title="Justified alignment"
        tabindex="-1"
      ><i class="fa fa-align-justify"></i></button>
    `
  },
}

const NEW_ALIGNMENT_GROUP_TEMPLATE = `
  <span
    class="trix-button-group trix-button-group--alignment-tools"
    data-trix-button-group="alignment-tools"
  ></span>
`

const getToolbarElement = (editorElement) => document.getElementById(editorElement.getAttribute("toolbar"))
const getH1Button = (toolbarElement) => toolbarElement.querySelector(SELECTORS.h1Button)
const getItalicButton = (toolbarElement) => toolbarElement.querySelector(SELECTORS.italicButton)
const getTextStyleGroup = (toolbarElement) => toolbarElement.querySelector(SELECTORS.groupTextTools)
const getTextAlignmentGroup = (toolbarElement) => toolbarElement.querySelector(SELECTORS.groupAlignmentTools)

const updateH1Button = (h1Button) => {
  h1Button.classList.remove("trix-button--icon")
  h1Button.textContent = "H1"
}

const addButtons = (toolbarElement) => {
  // Custom text styles in the first button group.
  let buttonToInsertAfter = getItalicButton(toolbarElement)

  for (const [_type, config] of Object.entries(NEW_TEXT_BUTTONS)) {
    const existingButton = toolbarElement.querySelector(config.selector)
    if (existingButton) { continue }

    buttonToInsertAfter.insertAdjacentHTML("afterend", config.template)
    buttonToInsertAfter = toolbarElement.querySelector(config.selector)
  }

  // Custom block styles in the second button group.
  buttonToInsertAfter = getH1Button(toolbarElement)

  for (const [_type, config] of Object.entries(NEW_BLOCK_BUTTONS)) {
    const existingButton = toolbarElement.querySelector(config.selector)
    if (existingButton) { continue }

    buttonToInsertAfter.insertAdjacentHTML("afterend", config.template)
    buttonToInsertAfter = toolbarElement.querySelector(config.selector)
  }


  // Custom alignment styles in a new, custom button group.
  // The group is sometimes duplicated, so we make sure to never create a second one.
  let textAlignmentGroup = getTextAlignmentGroup(toolbarElement)

  if (!textAlignmentGroup) {
    const textStyleGroup = getTextStyleGroup(toolbarElement)
    textStyleGroup.insertAdjacentHTML("afterend", NEW_ALIGNMENT_GROUP_TEMPLATE)
    textAlignmentGroup = getTextAlignmentGroup(toolbarElement)
  }

  for (const [_type, config] of Object.entries(NEW_ALIGNMENT_BUTTONS)) {
    const existingButton = toolbarElement.querySelector(config.selector)
    if (existingButton) { continue }

    textAlignmentGroup.insertAdjacentHTML("beforeend", config.template)
  }
}

const customizeButtons = (editorElement) => {
  const toolbarElement = getToolbarElement(editorElement)
  const h1Button = getH1Button(toolbarElement)
  if (!h1Button) { return }

  updateH1Button(h1Button)
  addButtons(toolbarElement)
  removeStrikeThroughButton(toolbarElement)
}

const removeStrikeThroughButton = (toolbarElement) => {
  const strikeThroughButton = toolbarElement.querySelector(SELECTORS.strikeThroughButton)
  if (!strikeThroughButton) { return }

  strikeThroughButton.classList.add("d-none")
}

// This is currently only used for the landing page bottom block content. We mainly want to use bullet points here, so we remove loads of buttons that don't apply.
const showMinimalButtonSet = (editorElement)=> {
  const toolbarElement = getToolbarElement(editorElement)

  toolbarElement.querySelector('[data-trix-button-group="alignment-tools"]')?.remove()
  toolbarElement.querySelector('[data-trix-button-group="file-tools"]')?.remove()
  toolbarElement.querySelector('[data-trix-attribute="heading1"]')?.remove()
  toolbarElement.querySelector('[data-trix-attribute="heading2"]')?.remove()
  toolbarElement.querySelector('[data-trix-attribute="heading3"]')?.remove()
  toolbarElement.querySelector('[data-trix-attribute="mark"]')?.remove()
  toolbarElement.querySelector('[data-trix-attribute="quote"]')?.remove()
  toolbarElement.querySelector('[data-trix-attribute="code"]')?.remove()
  toolbarElement.querySelector('[data-trix-attribute="number"]')?.remove()
  toolbarElement.querySelector('[data-trix-action="decreaseNestingLevel"]')?.remove()
  toolbarElement.querySelector('[data-trix-action="increaseNestingLevel"]')?.remove()
}

export default class extends Controller {
  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return }

    this.customizeAllEditors = this.customizeAllEditors.bind(this)

    document.addEventListener("turbo:load", this.customizeAllEditors)
    this.customizeAllEditors()
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.customizeAllEditors)
  }

  customizeAllEditors() {
    document.querySelectorAll("trix-editor").forEach((editorElement) => {
      if (editorElement.closest("[data-trix-customization-minimal]")) {
        showMinimalButtonSet(editorElement)
      } else {
        customizeButtons(editorElement)
      }
    })
  }
}
