import { Controller } from "stimulus"
import flatpickr from "flatpickr"
import "flatpickr/dist/themes/light.css"
import flatpickrLocales from "flatpickr/dist/l10n"

export default class extends Controller {
  static values = { url: String, initial: String, min: String }
  static targets = ["dropdown", "form", "dateInput"]

  // lifecycle
  connect() {
    const locale = document.querySelector("body").dataset.locale || "de"

    this.instance = flatpickr(this.dropdownTarget, {
      onChange: ([dateObject], dateString) => { this.onChange(dateString) },
      defaultDate: this.initialValue,
      inline: true,
      minDate: this.minValue,
      // TODO Reduce bundle size (beware: locale 'en' is named 'default'): (await import(`flatpickr/dist/l10n/${locale}`).default?.[locale]
      locale: flatpickrLocales[locale]
    })
  }

  disconnect() {
    if (this.instance) { this.instance.destroy() }
  }

  // events
  async onChange(dateString) {
    this.dateInputTarget.value = dateString
    this.formTarget.requestSubmit()
  }
}
