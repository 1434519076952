import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    dataUrl: String,
    error: String,
  }

  static targets = ["chart", "chartData"]

  // Events
  serializeDiagram(event) {
    const canvas = this.chartTarget.querySelector("canvas")
    // If no chart is present, we simply submit the form without adding any serialized data.
    if (!canvas) { return }

    event.preventDefault()
    const temporaryCanvas = document.createElement("canvas")
    temporaryCanvas.width = canvas.width
    temporaryCanvas.height = canvas.height

    const temporaryContext = temporaryCanvas.getContext("2d")
    temporaryContext.drawImage(canvas, 0, 0)
    const canvasData = temporaryCanvas.toDataURL()

    this.chartDataTargets.forEach(element => element.value = canvasData)

    event.target.closest("form").requestSubmit()
  }
}
