import { Controller } from "stimulus"

import Quill from "quill"
import "quill-mention"

export default class extends Controller {

  static targets = [
    "contentEditor",
    "contentInput",
    "mentionedUserIdsInput",
    "userImage",
  ]

  static values = {
    users: Array
  }

  // Lifecycle

  connect() {
    if (!this.hasContentEditorTarget) { return }

    this.quill = new Quill(this.contentEditorTarget, {
      modules: {
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["@"],
          source: this.usersSource,
          renderItem: this.renderUser,
        }
      }
    })

    this.quill.on("text-change", this.onContentChange)
    this.customizeBackingHiddenInput()
  }

  // Public API

  clear() { this.quill.setText("") }

  // Private

  get contentEditorValue() {
    return this.quill.getContents()
      .ops
      .filter(op => typeof op.insert === "string" || op.insert?.mention)
      .map(op => op.insert?.mention
          ? (op.insert.mention.denotationChar + op.insert.mention.value)
          : op.insert
      )
      .join("")
  }

  get mentionedUserIds() {
    return this.quill.getContents().ops.filter(op => op.insert?.mention).map(op => op.insert.mention.id)
  }

  // Callbacks: arrow fns for callbacks to avoid .bind(this)

  usersSource = (searchTerm, renderList, _mentionChar) => {
    const values = this.usersValue

    if (searchTerm.length === 0) {
      renderList(values, searchTerm)
    } else {
      const matches = values.filter(v => v.value.toLowerCase().includes(searchTerm.toLowerCase()))
      renderList(matches, searchTerm)
    }
  }

  renderUser = (item, _searchTerm) => {
    const image = this.userImageTargets.find(el => el.dataset.userId === item.id)?.innerHTML
    return `${image}${item.value}`
  }

  onContentChange = () => {
    this.contentInputTarget.value = this.contentEditorValue
    this.mentionedUserIdsInputTarget.value = JSON.stringify(this.mentionedUserIds)
  }

  // Backing form field customization to enable native "This field is required" hints

  customizeBackingHiddenInput = () => {
    this.contentInputTarget.type = "text"
  }
}
